\<template>
  <b-container class="page-container cart-view">
    <bread-crumbs :currentpage="$t('cart')" />
    <h1>{{ $t('cart') }}</h1>
    <b-alert v-if="addedReservationItems" show variant="danger">
      {{ $t('cart.warning-reservation-items-added') }}
    </b-alert>

    <b-form
      v-if="this.$store.getters.cartCount > 0"
      @submit.prevent="updateCartAndContinue"
    >
      <b-row class="mb-4">
        <b-col cols="12">
          <current-rental-information
            :fullwidth="true"
            :mobile="$store.state.windowWidth < 768"
          />
        </b-col>

        <b-col md="12" lg="4" xl="2">
          <div></div>
        </b-col>
      </b-row>

      <div style="max-width: 100vw;">
        <div class="cart-item-table">
          <div class="cart-item-row cart-item-row-heading d-flex">
            <div class="cart-item-description">
              Artikel
            </div>
            <div class="cart-item-price">
              Prijs
            </div>
            <div class="cart-item-quantity">
              Aantal
            </div>
            <div class="cart-item-price-total">
              Totaal
            </div>
            <div class="cart-item-delete">
              Acties
            </div>
          </div>
          <div
            v-for="cartItem in items"
            :key="cartItem.CartItemID"
            class="cart-item-row"
          >
            <div class="cart-item-row-column cart-item-description">
              {{ cartItem.Description }}
            </div>
            <div class="cart-item-row-column cart-item-price">
              <div class="cart-item-column-label">Prijs:</div>
              <span
                >&euro;
                {{
                  (Math.round(cartItem.PriceIncVAT * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')
                }}</span
              >
            </div>
            <div class="cart-item-row-column cart-item-quantity">
              <div class="cart-item-column-label">Aantal:</div>
              <div>
                <b-button
                  :disabled="cartItem.Amount < 2 || loading"
                  @click="
                    updateCartQuantity({
                      item: cartItem,
                      amount: cartItem.Amount - 1
                    }) &&
                      cartItem.Amount-- &&
                      cartItem.maxAdded++
                  "
                  size="sm"
                >
                  <icon-minus />
                </b-button>

                <span class="pl-2 pr-2">{{ cartItem.Amount }}</span>

                <b-button
                  :disabled="cartItem.MaxPerCustomerLeftover <= 0 || loading"
                  @click="
                    updateCartQuantity({
                      item: cartItem,
                      amount: cartItem.Amount + 1
                    }) &&
                      cartItem.maxAdded-- &&
                      cartItem.amount++
                  "
                  size="sm"
                >
                  <icon-plus />
                </b-button>
              </div>
            </div>
            <div class="cart-item-row-column cart-item-price-total">
              <div class="cart-item-column-label">Totaal prijs:</div>
              <span>
                &euro;
                {{
                  (
                    Math.round(cartItem.PriceIncVAT * cartItem.Amount * 100) /
                    100
                  )
                    .toFixed(2)
                    .replace('.', ',')
                }}
              </span>
            </div>

            <div class="cart-item-row-column cart-item-delete">
              <div class="cart-item-column-label">Acties:</div>
              <div v-if="!isDeposit(cartItem.CartItemID)">
                <a
                  @click="deleteCartItemPrompt(cartItem.CartItemID)"
                  href="#"
                  >{{ $t('delete') }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-card class="mb-4 mt-2 text-right">
        <b-button
          :disabled="
            cartProblems != 0 || this.$store.getters.amountOfBikesLeft < 0
          "
          type="submit"
          class="float-right"
          variant="primary"
        >
          {{ $t('complete-request') }}
          <icon-spinner v-if="cartProcessing" class="fa-spin" />
        </b-button>
        <a :href="`/${this.$i18n.locale}`" class="btn btn-link shop-more">
          <icon-chevron-left />
          {{ $t('continue-shopping') }}</a
        >
      </b-card>
    </b-form>

    <cart-suggested-items :cart-items="cartItems" />

    <b-alert v-if="this.$store.getters.cartCount < 1" show variant="warning">
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="exclamation-circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="svg-inline--fa fa-exclamation-circle fa-w-16 fa-5x"
      >
        <path
          fill="currentColor"
          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          class=""
        ></path>
      </svg>
      {{ $t('cart.warning-no-items') }}
    </b-alert>
    <modal
      :message="deleteConfirmMessage"
      @confirm="deleteCartItem()"
      @hide="showDeleteConfirmationModal = false"
      :show="showDeleteConfirmationModal"
      modalid="promptDelete"
    />
  </b-container>
</template>
<script>
// @ is an alias to /src
import {
  getCart,
  deleteCartItem,
  updateCartItem,
  cartMutationCheck
} from '@/services/CartService'
import CurrentRentalInformation from '@/components/header/CurrentRentalInformation'
import CartSuggestedItems from '@/views/Cart/CartSuggestedItems'
import Vue from 'vue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import Modal from '@/components/global/modal-prompt.vue'
import IconMinus from '@/components/icons/IconMinus'
import IconPlus from '@/components/icons/IconPlus'
import IconSpinner from '@/components/icons/IconSpinner'
import IconChevronLeft from '@/components/icons/IconChevronLeft'

dayjs.extend(customParseFormat)

export default {
  name: 'Cart',
  components: {
    IconSpinner,
    IconPlus,
    IconMinus,
    IconChevronLeft,
    CurrentRentalInformation,
    Modal,
    CartSuggestedItems
  },
  metaInfo: {
    title: 'Cart'
  },
  data() {
    return {
      maxDate: dayjs()
        .add(25, 'years')
        .format('DD/MM/YYYY'),
      warehouse: null,
      retrievalDate: '',
      retrievalTime: '',
      returnDate: '',
      returnTime: '',
      deleteConfirmMessage: this.$t('confirm-cart-delete'),
      showDeleteConfirmationModal: false,
      disabled: [0, 6],
      buttonsDisabled: true,
      position: 'left',

      clear: false,
      lang: 'nl',
      placeholder: this.$t('select-a-date'),
      bus: new Vue(),
      businessHours: {},
      mutationProblems: {},
      resetTime: false,
      cartProcessing: false,
      isLocationOrDateChanged: true,
      loading: true,
      deleteCartItemID: null
    }
  },

  computed: {
    loadingDateWarehouse: function() {
      return this.$store.state.loadingDateWarehouse
    },
    addedReservationItems: function() {
      return this.$route.query.addedReservationItems
    },
    cartCache: function() {
      return this.$store.getters.cartCache
    },
    cartItems: function() {
      return this.cartCache.CartItems
    },
    DateTimeBusinessStart: function() {
      return this.$store.state.DateTimeBusinessStart
    },
    DateTimeBusinessEnd: function() {
      return this.$store.state.DateTimeBusinessEnd
    },
    cartProblems: function() {
      return this.$store.state.mutationProblems.length
    },
    items: function() {
      return this.$store.getters.cartCache.CartItems.Collection
    },
    subTotal: function() {
      const sumPrice = this.items
        .map(item => item.total)
        .reduce((a, c) => {
          return a + c
        })

      return (Math.round(sumPrice * 100) / 100).toFixed(2).replace('.', ',')
    },
    currentWarehouse: function() {
      return this.$store.state.activeWarehouse
    }
  },
  async mounted() {
    this.resetTime = true
  },
  async created() {
    this.cartProcessing = true
    await getCart()
    //this.items = this.cartCache.CartItems.Collection
    //this.items = await getCart()

    this.loading = false
    this.cartProcessing = false
  },
  methods: {
    refreshCart: async function() {
      this.loading = true
      this.cartProcessing = true

      await getCart()

      this.cartProcessing = false

      this.loading = false
    },
    isDeposit: function(cartItemID) {
      const cartItem = this.items.find(item => item.CartItemID === cartItemID)
      return cartItem.ItemType === 'Deposit'
    },
    deleteCartItemPrompt: function(cartItemID) {
      this.deleteCartItemID = cartItemID

      this.showDeleteConfirmationModal = true
    },
    deleteCartItem: async function() {
      await deleteCartItem({ cartItemID: this.deleteCartItemID })
      await getCart()
      this.showDeleteConfirmationModal = false
    },
    updateCartMutationProblems: async function() {
      this.buttonsDisabled = true
      this.mutationProblems = await cartMutationCheck({
        warehouseID: this.warehouse,
        dateTimeBusinessStart: this.retrievalDate,
        dateTimeBusinessEnd: this.returnDate
      })
      this.buttonsDisabled = false
    },
    hideModal: function() {
      this.$refs['modal-1'].hide()
    },
    getBus: function() {
      return this.bus
    },
    dayClick: async function() {},
    updateCartQuantity: async function({ item, amount }) {
      this.loading = true
      const result = await updateCartItem({
        cartItemID: item.CartItemID,
        amount
      })

      if (result) {
        const cartItems = await getCart()
        this.items = cartItems
      } else {
        alert(this.$t('item-not-in-stock'))
      }
      this.loading = false
    },

    async updateCartAndContinue(event) {
      event.preventDefault()
      this.cartProcessing = true
      //await this.updateCartMutationProblems()

      if (
        dayjs(this.retrievalDate, 'DD-MM-YYYY').format('X') >
        dayjs(this.returnDate, 'DD-MM-YYYY').format('X')
      ) {
        this.cartProcessing = false
        await alert(this.$t('retrieval-date-later-then-return-date'))
        return
      } else {
        if (this.$store.state.accounts.length === 0) {
          this.$router.push({
            name: 'cart-login',
            params: { lang: this.$i18n.locale }
          })
          return
        }

        this.$router.push({
          name: 'confirmation',
          params: { lang: this.$i18n.locale }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.cart-item-row-heading {
  background-color: #e5eaeb;
  font-weight: 700;
  div {
    padding: 12px 18px;
  }
}
.cart-item-heading-row {
  display: flex;

  div {
    display: flex;
  }
}

.cart-item-row {
  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #ebebeb;

  .cart-item-column-label {
    display: none;
  }

  .cart-item-row-column {
    height: 80px;
    display: flex;
    padding: 12px 18px;
    align-items: center;
  }

  .cart-item-description {
    width: 40%;
  }
  .cart-item-price {
    width: 10%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  .cart-item-quantity {
    width: 20%;
    text-align: right;
    display: flex;
    justify-content: center;
  }

  .cart-item-price-total {
    width: 20%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }

  .cart-item-delete {
    width: 10%;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 977px) {
  .cart-item-description {
    text-align: center;
    justify-content: center;
    width: 100% !important;
    border-bottom: 1px grey;
    font-weight: 700;
  }

  .cart-item-row {
    margin-bottom: 20px;
  }

  .cart-item-row-heading {
    display: none !important;
  }

  .cart-item-row-column {
    margin: 0px !important;
    height: auto;
    height: 50px !important;
    & > * {
      width: 50%;
    }
  }

  .cart-item-price {
    width: 100% !important;
  }

  .cart-item-quantity {
    width: 100% !important;
    justify-content: right !important;
  }

  .cart-item-price-total {
    width: 100% !important;
  }
  .cart-item-delete {
    width: 100% !important;
    justify-content: right !important;
    text-align: right;
  }
  .cart-item-column-label {
    display: block !important;
  }
}

.shop-more {
  svg {
    font-size: 13px;
  }
}
.label-padding {
  margin-top: 32px;
}
.cart-total-price {
  p {
    font-size: 18px;
    font-weight: 600;
  }
}
.cart-view .datepicker {
  width: 100%;
}
.datepicker-input {
  font-size: 1rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  height: 38px;
}

.warning {
  color: #d3732f;
}
</style>
